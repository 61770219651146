
import { Scenario } from '@/models';
import { HomeRoute, ScenarioEditRoute } from '@/router';
import { ApiService, LoaderService, UserService } from '@/services';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { RouteLocationRaw, useRouter } from 'vue-router';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Message from 'primevue/message';
import Badge from 'primevue/badge';
import { useToast } from 'primevue/usetoast';
import { ScenarioAddRoute } from '@/router';

interface ScenarioView extends Scenario {
	loading?: boolean;
}

export default defineComponent({
	components: { DataTable, Column, Badge, Message },
	props: {
		categoryId: String
	},
	setup(props) {
		const scenarios = ref<ScenarioView[]>([]);
		const expandedRows = ref<ScenarioView[]>([]);
		const loadToken = LoaderService.invoke();
		const router = useRouter();
		const toast = useToast();
		const isAdmin = UserService.isAdmin;

		const newScenariosMessage = computed(() => {
			const count = scenarios.value.filter(c => c.newAddition).length;
			switch (count) {
				case 0:
					return null;
				case 1:
					return `We've added a new scenario! Enable it below to see
		it in your future games.`;
				default:
					return `We've added ${count} new scenarios! Enable them below to see
		them in your future games.`;
			}
		});

		onMounted(async () => {
			try {
				scenarios.value = await ApiService.get<Scenario[]>(
					`/categories/${props.categoryId}/scenarios`
				);
			} catch (e) {
				toast.add({
					severity: 'error',
					summary: 'Error Occurred',
					detail: 'Unable to load scenarios. Try again later.',
					life: 3000
				});

				router.push({ name: HomeRoute });
			} finally {
				LoaderService.clear(loadToken);
			}
		});

		const toggle = async (scenario: ScenarioView) => {
			scenario.loading = true;
			try {
				const othersEnabled = scenarios.value.some(
					e => e.id !== scenario.id && e.enabled
				);

				if (scenario.enabled && !othersEnabled) {
					toast.add({
						severity: 'error',
						summary: 'Error Occurred',
						detail: 'Must have at least one scenario enabled.',
						life: 3000
					});

					return;
				}

				const updated = await ApiService.post<Scenario>(
					`/scenarios/${scenario.id}/toggle`
				);
				Object.assign(scenario, updated);
			} catch (e) {
				toast.add({
					severity: 'error',
					summary: 'Error Occurred',
					detail: 'Unable to toggle scenario. Try again later.',
					life: 3000
				});
			} finally {
				scenario.loading = false;
			}
		};

		const toggleExpand = (scenario: Scenario) => {
			if (!expandedRows.value) expandedRows.value = [];
			if (expandedRows.value?.some(e => e.id === scenario.id)) {
				expandedRows.value = expandedRows.value.filter(
					e => e.id !== scenario.id
				);
			} else {
				expandedRows.value.push(scenario);
				expandedRows.value = [...expandedRows.value];
			}
		};

		const handleRowClass = (scenario: Scenario) => {
			if (isAdmin.value) return '';
			return scenario.enabled ? '' : 'disabled';
		};

		const editRoute = (scenarioId: number): RouteLocationRaw => ({
			name: ScenarioEditRoute,
			params: { scenarioId }
		});

		return {
			newScenariosMessage,
			scenarios,
			expandedRows,
			ScenarioAddRoute,
			editRoute,
			handleRowClass,
			toggle,
			toggleExpand,
			isAdmin
		};
	}
});
